import axios from 'axios';

const blogFormId = process.env.NEXT_PUBLIC_STRAPI_BLOG_ID

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_WORDPRESS_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 60000,
});

const strapiInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_STRAPI_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 60000,
});

export const fetchPosts = async (page = 1, categoryId = null, perPage = null) => {
  let url = `/posts?page=${page}&status=publish&orderby=date&_embed`;

  if (categoryId) {
    url += `&categories=${categoryId}`;
  }
  if (perPage) {
    url += `&per_page=${perPage}`;
  }
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error.response?.data || error.message);
    throw new Error('Failed to fetch posts');
  }
};

export const fetchPostBySlug = async (slug) => {
  try {
    const response = await axiosInstance.get(`/posts?slug=${slug}&_embed`);
    return response.data.length > 0 ? response.data[0] : null;
  } catch (error) {
    console.error('Error fetching post by slug:', error.response?.data || error.message);
    throw new Error('Failed to fetch post');
  }
};

export const fetchCategories = async () => {
  try {
    const response = await axiosInstance.get('/categories?orderby=name&hide_empty=true&exclude=1422');
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error.response?.data || error.message);
    throw new Error('Failed to fetch categories');
  }
};

export const fetchCategoryBySlug = async (slug) => {
  try {
    const response = await axiosInstance.get(`/categories?slug=${slug}`);
    return response.data.length > 0 ? response.data[0] : null;
  } catch (error) {
    console.error(`Error fetching category with slug "${slug}":`, error.response?.data || error.message);
    throw new Error(`Failed to fetch category with slug "${slug}"`);
  }
};

export const fetchCategoryById = async (id) => {
  try {
    const response = await axiosInstance.get(`/categories/${id}`);
    return response.data.length > 0 ? response.data[0] : null;
  } catch (error) {
    console.error(`Error fetching category with slug "${id}":`, error.response?.data || error.message);
    throw new Error(`Failed to fetch category with slug "${id}"`);
  }
};

export async function fetchHeaderFooter() {
  try {
    const [headerRes, footerRes, navigationRes] = await Promise.all([
      strapiInstance.get('/header'),
      strapiInstance.get('/footer'),
      strapiInstance.get('/navigations/1'),

    ]);
    return {
      header: headerRes.data,
      footer: footerRes.data,
      navigation: navigationRes.data,
    };
  } catch (error) {
    console.error('Error fetching header and footer:', error.response?.data || error.message);
    throw new Error('Failed to fetch header or footer');
  }
}

export async function fetchMicroData() {
  try {
    const [headerRes] = await Promise.all([
      strapiInstance.get('/microdata'),
    ]);
    return {
      microdata: headerRes.data,
    };
  } catch (error) {
    console.error('Error fetching microdata:', error.response?.data || error.message);
    throw new Error('Failed to fetch microdata');
  }
}

export const fetchRelatedPosts = async (postId) => {
  try {
    const response = await axiosInstance.get(`/related-posts/${postId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching related posts:', error.response?.data || error.message);
    throw new Error('Failed to fetch related posts');
  }
};

export async function fetchHubspotForm() {
  try {
    const response = await strapiInstance.get(`/hubspot-forms/${blogFormId}`);
    return { forms: response.data };
  } catch (error) {
    console.error('Error fetching HubSpot form:', error.response?.data || error.message);
    throw new Error('Failed to fetch HubSpot form');
  }
}

