import App from 'next/app';
import "../../public/styles/tailwind.css";
import { fetchHeaderFooter, fetchMicroData } from '../lib/utils';

export default function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />;
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const isBlogPage = appContext.router.pathname.startsWith("/blog");

  if (isBlogPage) {
    const [{ header, footer, navigation }, globalMicrodata] = await Promise.all([
      fetchHeaderFooter(),
      fetchMicroData(),
    ]);

    return {
      ...appProps,
      pageProps: {
        ...appProps.pageProps,
        sharedData: {
          headerFooterData: { header, footer, navigation },
          globalMicrodata,
        },
      },
    };
  }

  return appProps;
};